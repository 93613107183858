import ptLocale from 'element-ui/lib/locale/lang/en'
export default {
	Invoice: {
		MoldSetupFee:'Taxa de moldagem/instalação',

		invoice:"FATURA",
		PONumber:"Número do pedido",
		reference:"Referência",
		HSCODE:"CÓDIGO HS",
		PURCHASEINVOICE:"NOTA FISCAL DE COMPRA",
		productPrintNo:"produtoImprimir Não",
		BILLTO:"PROJETO DE LEI PARA",
		SHIPTO:"ENVIAR PARA",
		ProductDescription:"Descrição do produto",
		total:"Total",
		PaymentStatus:"Status do pagamento",
		checkInfo:"Verifique as informações",
		bankInfo:"Informações bancárias",
		payOnline:"Pague on-line",
		checkPay:"Verifique: Pague e envie para as informações abaixo",
		attentionPlease:"Lembre-se",
		directorOperations:"Diretor de Operações",
		signature:"Assinatura",
		nodesText:"Se você estiver fazendo o pagamento através do banco, certifique-se de incluir seu",
		nodesText2:"no memorando.",
		thankbusiness:"Agradeço pelos seus serviços!",
		paid:"PAGO",
		unpaid:"NÃO PAGO",
		payInstall:"Pagamento parcial, aguarde o saldo.",
		remember:" Para Contas Offshore e Contas em Moeda Estrangeira que são usadas por clientes estrangeiros para remessas ao nosso banco, o uso de fundos deve ser 'Pagamento de Mercadorias', ou deixar em branco.",
		extraText:[
			"",
			"Taxa urgente",
			"Taxa de impressão em serigrafia",
			"Taxa de molde traseiro",
			"Cobrança Impressa",
			"Custo do material de fundição sob pressão de zinco",
			"Frete Extra",
			"Taxa de produtos licenciados",
			"Quantidade"
		],
		TaxPrice:"Preço do imposto",
		discountedAmount:"Valor com desconto",


		card:"1. Cartão de crédito: VISA/Mastercard/AMEX e Discover",
		bankNote:"Observação: Se desejar pagar em uma moeda diferente de EUR, entre em contato com nosso representante de atendimento ao cliente.",
		checkFollow:"2. Encontre nossas informações de cheque da seguinte forma:",
		mention:"Ao efetuar o pagamento, mencione o número da fatura. Obrigado!",
		topic:"Tópico: Assessoria financeira",
		invoiceDate:"Data da fatura",
		invoiceNumber:"Número da fatura",
		excluding:"Subtotal excluindo",
		phone: "Telefone",
		email: "E-mail",
		from: "De",
		to: "Para",
		attn: "Atenção",
		lNVOICE: "FATURA",
		PROFORMA:"FATURA PROFORMA",
		invoiceNo: "Número da Fatura",
		date: "Data",
		paymentMethod: "Forma de pagamento",
		terms:"Termos",
		title: "Título",
		no: "Não",
		artwork: "Obra de arte",
		item: "Item",
		description: "Descrição",
		qty: "quantidade",
		unitPrice: "Preço unitário",
		moldFee: "Taxa de Molde",
		extraFee: "Taxa extra",
		amount: "Quantia",
		subtotal: "Subtotal",
		apoShippingFee: "Taxa de envio Apo",
		taxPrice: "Preço do imposto",
		couponAdditionalCosts:"Custo adicional",
		firstOrderCodeDisc: "Desconto no primeiro pedido",
		promoCode: "Código promocional",
		specialCode:"Ofertas Especiais para Grupos",
		custompensCode:"$ 1 por canetas de 20 unidades",
		additionalCode:"$ 1 por um voucher de $ 75",
		pointsDeduct: "Dedução de pontos",
		points:"Pontos",
		giftVouchers: "cartões de presente",
		couponDiscount: "cupom de desconto",
		orderDiscount: "Desconto de pedido",
		extraDiscount: "desconto extra",
		finalPrice: "Preço final",
		finalTotalPrice: "Preço total final",
		estimatedShipDate: "Data estimada de envio",
		notes: "Notas",
		shipTo: " Enviar para",
		name: "Nome",
		check: "Verificar",
		APOFPOAddress: "Endereço APO/FPO",
		Address: "Endereço",
		GrandTotal: "Total geral",
		AmountPaid: "Quantia paga",
		BalanceDue: "Saldo devedor",
		UserLevelDiscount: "Desconto no nível do usuário"
	},
	Quotation: {
		free:"LIVRE",
		company:"Empresa",
		off:"DESLIGADO",
		additionalCosts:"Custos adicionais",
		firstOrderDiscount:"Desconto no primeiro pedido",
		specialGroup:"Ofertas Especiais para Grupos",
		pointsDeduct:"Dedução de pontos",
		giftVouchers:"cartões de presente",
		couponDiscount:"Desconto de cupom",
		goldVipMember:"Membro Vip Ouro",
		extraDiscount:"Desconto Extra",
		userLevelDiscount:"Desconto no nível do usuário",
		points:"Pontos",
		title: "COTAÇÃO",
		to: "Para",
		from: "De",
		name: "Nome",
		email: "E-mail",
		telephone: "Telefone",
		country: "País",
		oid: "ID do pedido",
		date: "Data de cotação",
		valid: "Válido até",
		contacts: "Contatos",
		currency: "Moeda",
		no: "Não.",
		item: "Item",
		artwork: "Obra de arte",
		description: "Descrição",
		qty: "Quantidade",
		unitPrice: "Preço unitário",
		moldFee: "Taxa de Molde",
		extraFee: "Taxa extra",
		apoShippingFee: "Taxa de envio Apo",
		taxPrice: "Preço do imposto",
		amount: "Quantia",
		remarks: "Observações",
		total: "Total",
		titleTerms: "Termos e Condições:",
		first: "1. Arte gratuita e frete grátis para EUA/Canadá/Europa.",
		second: "2. As informações acima não são uma fatura e apenas uma estimativa dos serviços/bens descritos acima.",
		third: "3. A produção começará na data em que recebermos a aprovação e o pagamento da prova. Pague este link para o seu pedido com base na prova aprovada. (Link de pagamento:",
		end: "Agradeço pelos seus serviços!",
		tel: "Tel",
		hours: "Horário de atendimento",
		chat: "Bate-papo ao vivo",
		address: "Endereço dos EUA",
		hoursItem: "De segunda a sexta-feira, das 8h30 às 17h30, horário do Pacífico (PT)",
		chatItem: "24 horas de segunda a sexta",
		addressItem: "20829 Valley Blvd. Walnut, CA 91789"
	},
	member:{
		member1:"Membro Prata",
		member4:"Membro VIP Ouro",
		member5:"Membro VIP Platina",
	},
	...ptLocale
}
