// lang/index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import csLocale from './cs'
import daLocale from './da'
import deLocale from './de'
import enLocale from './en'
import esLocale from './es'
import frLocale from './fr'
import gaLocale from './ga'
import hiLocale from './hi'
import huLocale from './hu'
import itLocale from './it'
import jaLocale from './ja'
import koLocale from './ko'
import nlLocale from './nl.js'
import noLocale from './no'
import ptLocale from './pt'
import skLocale from './sk'
import svLocale from './sv'
import zhLocale from './zh'


const DEFAULT_LANG = navigator.language || 'zh'
const LOCALE_KEY = 'localeLanguage'

Vue.use(VueI18n)

const messages = {
    en: enLocale,
    zh: zhLocale,
    cs:csLocale,
    da:daLocale,
    de:deLocale,
    es:esLocale,
    fr:frLocale,
    ga:gaLocale,
    hi:hiLocale,
    hu:huLocale,
    it:itLocale,
    ja:jaLocale,
    ko:koLocale,
    nl:nlLocale,
    no:noLocale,
    pt:ptLocale,
    sk:skLocale,
    sv:svLocale,
}

const i18n = new VueI18n({
    locale: DEFAULT_LANG,
    messages,
    fallbackLocale: 'zh',
    silentTranslationWarn: true,
    formatFallbackMessages: true,
    silentFallbackWarn: true
})

export const setup = lang => {
    if (lang === undefined) {
        lang = window.localStorage.getItem(LOCALE_KEY)
        if (messages[lang] === undefined) {
            lang = DEFAULT_LANG
        }
    }
    window.localStorage.setItem(LOCALE_KEY, lang)
    Vue.config.lang = lang
    i18n.locale = lang
    return lang
}

export default i18n

