import itLocale from 'element-ui/lib/locale/lang/en'
export default {
	Invoice: {
		MoldSetupFee:'Tassa di configurazione/muffa',
		
		invoice:"FATTURA",
		PONumber:"Numero dell'ordine d'acquisto",
		reference:"Riferimento",
		HSCODE:"CODICE SA",
		PURCHASEINVOICE:"FATTURA D'ACQUISTO",
		productPrintNo:"prodottoStampa n",
		BILLTO:"DISEGNO DI LEGGE PER",
		SHIPTO:"SPEDIRE A",
		ProductDescription:"Descrizione del prodotto",
		total:"Totale",
		PaymentStatus:"Stato del pagamento",
		checkInfo:"Controlla le informazioni",
		bankInfo:"Informazioni bancarie",
		payOnline:"Paga in linea",
		checkPay:"Controlla: paga e invia per posta alle informazioni riportate di seguito",
		attentionPlease:"Ricorda",
		directorOperations:"direttore delle operazioni",
		signature:"Firma",
		nodesText:"Se effettui il pagamento tramite banca, assicurati di includere il tuo",
		nodesText2:"nel promemoria.",
		thankbusiness:"Grazie per il tuo business!",
		paid:"PAGATO",
		unpaid:"NON PAGATO",
		payInstall:"Pagamento parziale, attendere il saldo.",
		remember:" per i conti offshore e i conti in valuta estera utilizzati da clienti stranieri per effettuare rimesse alla nostra banca, l'utilizzo dei fondi deve essere \"Pagamento per merci\" o lasciarlo vuoto.",
		extraText:[
			"",
			"Tariffa urgente",
			"Tariffa di stampa serigrafica",
			"Tariffa per lo stampo posteriore",
			"Addebito stampato",
			"Costo del materiale di pressofusione di zinco",
			"Trasporto extra",
			"Tariffa per i prodotti concessi in licenza",
			"Quantità"
		],
		TaxPrice:"Prezzo fiscale",
		discountedAmount:"Importo scontato",


		card:"1. Carta di credito: VISA / Mastercard / AMEX e Discover",
		bankNote:"Nota: se desideri pagare in una valuta diversa dall'EUR, contatta il nostro rappresentante del servizio clienti.",
		checkFollow:"2. Trova le nostre informazioni di controllo come segue:",
		mention:"Quando effettui il pagamento, menziona il numero della fattura. Grazie!",
		topic:"Argomento: consulenza finanziaria",
		invoiceDate:"Data fattura",
		invoiceNumber:"Numero di fattura",
		excluding:"Subtotale escluso",
		phone: "Telefono",
		email: "E-mail",
		from: "Da",
		to: "A",
		attn: "Att",
		lNVOICE: "FATTURA",
		PROFORMA:"FATTURA PROFORMA",
		invoiceNo: "Fattura n",
		date: "Data",
		paymentMethod: "Metodo di pagamento",
		terms:"Termini",
		title: "Titolo",
		no: "NO",
		artwork: "Opera d'arte",
		item: "Articolo",
		description: "Descrizione",
		qty: "Qtà",
		unitPrice: "Prezzo unitario",
		moldFee: "Tassa di muffa",
		extraFee: "Tassa aggiuntiva",
		amount: "Quantità",
		subtotal: "totale parziale",
		apoShippingFee: "Tassa di spedizione Apo",
		taxPrice: "Prezzo fiscale",
		couponAdditionalCosts:"Costo aggiuntivo",
		firstOrderCodeDisc: "Sconto sul primo ordine",
		promoCode: "Codice promozionale",
		specialCode:"Offerte speciali per gruppi",
		custompensCode:"$ 1 per 20 penne",
		additionalCode:"$ 1 per un buono da $ 75",
		pointsDeduct: "Detrazione punti",
		points:"Punti",
		giftVouchers: "buoni regalo",
		couponDiscount: "Buono Sconto",
		orderDiscount: "Sconto ordine",
		extraDiscount: "Sconto extra",
		finalPrice: "Prezzo finale",
		finalTotalPrice: "Prezzo totale finale",
		estimatedShipDate: "data di spedizione stimata",
		notes: "Appunti",
		shipTo: " Spedire a",
		name: "Nome",
		check: "Controllo",
		APOFPOAddress: "Indirizzo APO/FPO",
		Address: "Indirizzo",
		GrandTotal: "Somma totale",
		AmountPaid: "Importo pagato",
		BalanceDue: "Saldo dovuto",
		UserLevelDiscount: "Sconto a livello utente"
	},
	Quotation: {
		free:"GRATUITO",
		company:"Azienda",
		off:"SPENTO",
		additionalCosts:"Costi aggiuntivi",
		firstOrderDiscount:"Sconto sul primo ordine",
		specialGroup:"Offerte speciali per gruppi",
		pointsDeduct:"Detrazione punti",
		giftVouchers:"buoni regalo",
		couponDiscount:"Sconto buono",
		goldVipMember:"Membro VIP Oro",
		extraDiscount:"Sconto extra",
		userLevelDiscount:"Sconto a livello utente",
		points:"Punti",
		title: "QUOTAZIONE",
		to: "A",
		from: "Da",
		name: "Nome",
		email: "E-mail",
		telephone: "Telefono",
		country: "Paese",
		oid: "ID ordine",
		date: "Data di quotazione",
		valid: "Valido fino a",
		contacts: "Contatti",
		currency: "Valuta",
		no: "NO.",
		item: "Articolo",
		artwork: "Opera d'arte",
		description: "Descrizione",
		qty: "Quantità",
		unitPrice: "Prezzo unitario",
		moldFee: "Tassa di muffa",
		extraFee: "Tassa aggiuntiva",
		apoShippingFee: "Tassa di spedizione Apo",
		taxPrice: "Prezzo fiscale",
		amount: "Quantità",
		remarks: "Osservazioni",
		total: "Totale",
		titleTerms: "Termini & Condizioni:",
		first: "1. Opere d'arte gratuite e spedizione gratuita negli Stati Uniti / Canada / Europa.",
		second: "2. Le informazioni di cui sopra non sono una fattura e solo una stima dei servizi/beni sopra descritti.",
		third: "3. La produzione inizierà alla data in cui riceviamo l'approvazione della prova e il pagamento, si prega di pagare questo link per il tuo ordine in base alla prova approvata. (Link di pagamento:",
		end: "Grazie per il tuo business!",
		tel: "tel",
		hours: "Orario di ufficio",
		chat: "Chat dal vivo",
		address: "Indirizzo USA",
		hoursItem: "Dal lunedì al venerdì, dalle 8:30 alle 17:30, ora del Pacifico (PT)",
		chatItem: "24 ore dal lunedì al venerdì",
		addressItem: "Viale della Valle 20829 Noce, CA 91789"
	},
	member:{
		member1:"Membro d'argento",
		member4:"Membro VIP Oro",
		member5:"Membro VIP platino",
	},
	...itLocale
}
