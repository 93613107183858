import noLocale from 'element-ui/lib/locale/lang/en'
export default {
	Invoice: {
		MoldSetupFee:'Mold/Setup Fee',

		invoice:"FAKTURA",
		PONumber:"postnummer",
		reference:"Henvisning",
		HSCODE:"HS KODE",
		PURCHASEINVOICE:"KJØPSFAKTURA",
		productPrintNo:"produktUtskriftsnr",
		BILLTO:"BILL TIL",
		SHIPTO:"SEND TIL",
		ProductDescription:"produktbeskrivelse",
		total:"Total",
		PaymentStatus:"Betalingsstatus",
		checkInfo:"Sjekk info",
		bankInfo:"Bankinfo",
		payOnline:"Betal online",
		checkPay:"Sjekk: Betal og send til info nedenfor",
		attentionPlease:"Husk",
		directorOperations:"Driftsdirektør",
		signature:"Signatur",
		nodesText:"Hvis du betaler gjennom banken, må du sørge for å inkludere din",
		nodesText2:"i notatet.",
		thankbusiness:"Takk for handelen!",
		paid:"BETALT",
		unpaid:"UBETALT",
		payInstall:"Delbetaling, vent på saldoen.",
		remember:" For offshore-konto og utenlandsk valuta-konto som brukes av utenlandske kunder til å overføre til banken vår, bør bruken av midler være \"Betaling for varer\", eller la det stå tomt.",
		extraText:[
			"",
			"Rush Fee",
			"Silketrykkgebyr",
			"Tilbake Mold Fee",
			"Utskrevet Charge",
			"Sinkstøpingsmaterialekostnad",
			"Ekstra frakt",
			"Gebyr for lisensierte produkter",
			"Mengde"
		],
		TaxPrice:"Skattepris",
		discountedAmount:"Rabattert beløp",


		card:"1. Kredittkort: VISA / Mastercard / AMEX og Discover",
		bankNote:"Merk: Hvis du ønsker å betale i en annen valuta enn EUR, vennligst ta kontakt med vår kundeservicerepresentant.",
		checkFollow:"2. Finn sjekkinformasjonen vår som følger:",
		mention:"Når du foretar betalingen, vennligst oppgi fakturanummer. Takk skal du ha!",
		topic:"Tema: Økonomirådgivning",
		invoiceDate:"Fakturadato",
		invoiceNumber:"Fakturanummer",
		excluding:"Delsum ekskludert",
		phone: "Telefon",
		email: "E-post",
		from: "Fra",
		to: "Til",
		attn: "Attn",
		lNVOICE: "FAKTURA",
		PROFORMA:"PROFORMA FAKTURA",
		invoiceNo: "Faktura nr",
		date: "Dato",
		paymentMethod: "Betalingsmetode",
		terms:"Vilkår",
		title: "Tittel",
		no: "Nei",
		artwork: "Kunstverk",
		item: "Punkt",
		description: "Beskrivelse",
		qty: "Antall",
		unitPrice: "Enhetspris",
		moldFee: "Muggavgift",
		extraFee: "Ekstra avgift",
		amount: "Beløp",
		subtotal: "Delsum",
		apoShippingFee: "Apo fraktgebyr",
		taxPrice: "Skattepris",
		couponAdditionalCosts:"Ekstra kostnader",
		firstOrderCodeDisc: "Første bestillingsrabatt",
		promoCode: "Rabattkode",
		specialCode:"Spesielle gruppetilbud",
		custompensCode:"$1 for 20 stk penner",
		additionalCode:"$1 for en $75-kupong",
		pointsDeduct: "Poengtrekk",
		points:"Poeng",
		giftVouchers: "gavekort",
		couponDiscount: "Kupong rabatt",
		orderDiscount: "Bestill rabatt",
		extraDiscount: "Ekstra rabatt",
		finalPrice: "Siste pris",
		finalTotalPrice: "Endelig totalpris",
		estimatedShipDate: "Anslått forsendelsesdato",
		notes: "Notater",
		shipTo: " Send til",
		name: "Navn",
		check: "Kryss av",
		APOFPOAddress: "APO/FPO-adresse",
		Address: "Adresse",
		GrandTotal: "Grand Total",
		AmountPaid: "Beløpet betalt",
		BalanceDue: "Forfalt saldo",
		UserLevelDiscount: "Rabatt på brukernivå"
	},
	Quotation: {
		free:"GRATIS",
		company:"Selskap",
		off:"AV",
		additionalCosts:"Tilleggskostnader",
		firstOrderDiscount:"Første bestillingsrabatt",
		specialGroup:"Spesielle gruppetilbud",
		pointsDeduct:"Poengtrekk",
		giftVouchers:"gavekort",
		couponDiscount:"Kupong rabatt",
		goldVipMember:"Gull VIP-medlem",
		extraDiscount:"Ekstra rabatt",
		userLevelDiscount:"Rabatt på brukernivå",
		points:"Poeng",
		title: "SITAT",
		to: "Til",
		from: "Fra",
		name: "Navn",
		email: "E-post",
		telephone: "Telefon",
		country: "Land",
		oid: "Bestillings ID",
		date: "Tilbudsdato",
		valid: "Gyldig til",
		contacts: "Kontakter",
		currency: "Valuta",
		no: "Nei.",
		item: "Punkt",
		artwork: "Kunstverk",
		description: "Beskrivelse",
		qty: "Mengde",
		unitPrice: "Enhetspris",
		moldFee: "Muggavgift",
		extraFee: "Ekstra avgift",
		apoShippingFee: "Apo fraktgebyr",
		taxPrice: "Skattepris",
		amount: "Beløp",
		remarks: "Merknader",
		total: "Total",
		titleTerms: "Betingelser og vilkår:",
		first: "1. Gratis kunstverk og gratis frakt til USA / Canada / Europa.",
		second: "2. Informasjonen ovenfor er ikke en faktura og kun et estimat av tjenester/varer beskrevet ovenfor.",
		third: "3.Produksjonen vil starte på datoen vi mottar din bevisgodkjenning og betaling, vennligst betal denne lenken for bestillingen din basert på det godkjente beviset. (Betalingslenke:",
		end: "Takk for handelen!",
		tel: "Tlf",
		hours: "Kontortid",
		chat: "Live chat",
		address: "USA adresse",
		hoursItem: "Mandag – fredag 08:30 til 17:30 Pacific Time (PT)",
		chatItem: "24 timer mandag til fredag",
		addressItem: "20829 Valley Blvd. Walnut, CA 91789"
	},
	member:{
		member1:"Sølv medlem",
		member4:"Gull VIP-medlem",
		member5:"Platinum VIP-medlem",
	},
	...noLocale
}
