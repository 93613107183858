<template>
	<div v-if="!item.hidden">
		<template
			v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
			<app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
				<el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}" style="cursor: pointer">
					<item :icon="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)"/>
					<span slot='title' @click="clearOrder(item)">{{ $t(onlyOneChild.meta.title) }}
						<span style="font-size: 18px;font-style: italic" v-if="item&&item.name==='inquiry'">
						  <strong style="color: #DE3500">{{ getUnreadData("inquiryUnread") }}</strong>
						</span>
						<span style="font-size: 18px;font-style: italic" v-if="item&&(item.name==='order' || item.name==='fdOrder')">
						  <strong style="color: #DE3500">{{ getUnreadData("orderUnread") }}</strong>
							<span class="orderIcon" @click.prevent="changeOrder(item.name)">
								<i class="el-submenu__icon-arrow el-icon-arrow-down" :class="{down:!activeOrder}"></i>
							</span>
						</span>
						<span style="font-size: 18px;font-style: italic" v-if="item&&(item.name==='linkOrder' || item.name==='fdLinkOrder')">
						  <strong style="color: #DE3500">{{ getUnreadData("linkOrderUnread") }}</strong>
							<span class="orderIcon" @click.prevent="changeOrder(item.name)">
								<i class="el-submenu__icon-arrow el-icon-arrow-down" :class="{down:!activeLink}"></i>
							</span>
						</span>

						<span style="font-size: 18px;font-style: italic" v-if="item&&item.name==='InternalProblemManagement'">
						  <strong><i v-if="getProblemState[0]>0" class="el-icon-info"
									 style=" color: red;vertical-align: text-top;font-size: 14px;"></i></strong>
						</span>
						<span style="font-size: 18px;font-style: italic" v-if="item&&item.name==='factoryProblem'">
						  <strong><i v-if="getProblemState[1]>0" class="el-icon-info"
									 style=" color: red;vertical-align: text-top;font-size: 14px;"></i></strong>
						</span>
					</span>
				</el-menu-item>
				<template v-if="item && ['order','linkOrder','fdOrder','fdLinkOrder'].includes(item.name)">
					<div v-if="((item.name==='order' || item.name==='fdOrder')&&activeOrder) || ((item.name==='linkOrder' ||item.name==='fdLinkOrder' )&&activeLink)" @click.stop>
						<div class="paymentMenu" :class="{active:idx == $route.query.paymentType}" v-for="(ele,idx) in $store.state.manage.paymentStatus" :key="idx" @click.prevent="toOrder(item,ele,idx)">
							{{ele.menu}}
						</div>
					</div>
				 </template>
			</app-link>
		</template>

		<el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
			<template #title>
				<item v-if="item.meta" :icon="item.meta && item.meta.icon"/>
				<span slot='title' :class="{isCollapse:isCollapse}">{{ $t(item.meta.title) }}</span>
			</template>
			<template v-if="item&&item.children.length>0">
				<sidebar-item v-for="child in item.children" :key="child.path" :is-nest="true" :item="child"
							  :base-path="resolvePath(child.path)" class="nest-menu" :class="{collapse:isCollapse}">
				</sidebar-item>
			</template>
		</el-submenu>
	</div>
</template>

<script>
import AppLink from "./Link";
import Item from "./Item";
import path from "path";
import {isExternal} from "@/utils/utils";

export default {
	name: "SidebarItem",
	inject: ["reload"],
	components: {AppLink, Item},
	props: {
		item: {
			type: Object,
			required: true
		},
		isNest: {
			type: Boolean,
			default: false
		},
		basePath: {
			type: String,
			default: ""
		}
	},
	computed: {
		isCollapse() {
			return this.$store.state.manage.navBar;
		},
		getProblemState() {
			return this.$store.state.manage.problemState;
		},
	},
	data() {
		return {
			onlyOneChild: null,
			activeIndex: -1,
			activeOrder: false,
			activeLink: false
		};
	},
	methods: {
		changeOrder(name) {
			let result = ['order','linkOrder','fdOrder','fdLinkOrder'].includes(name)
			if (!result) {
				return;
			}
			if (name == "order" || name == "fdOrder") {
				this.activeOrder = !this.activeOrder;
			} else {
				this.activeLink = !this.activeLink;
			}
		},
		toOrder(obj, item, index) {
			let name = obj.name;
			if(this.$route.path.includes(name) &&  this.$route.query.paymentType == item.status){
				return
			}
			this.$store.commit("manage/changeResetForm", true);
			this.activeIndex = index;
			this.$store.commit('manage/selectOrderPayment',true)
			if(name == 'fdLinkOrder' || name == 'fdOrder'){
				this.$router.replace({
					// name: name == 'fdLinkOrder'? 'linkOrderManage':'/InquiryOrderManage/order/orderManage',
					path: name == 'fdLinkOrder'? '/FdInquiryOrderManage/linkOrder/linkOrderManage':'/FdInquiryOrderManage/order/orderManage',
					query:{
						paymentType: item.status
					}
				}).catch(err=>{
				})
			}else{
				this.$router.replace({
					name: name == 'linkOrder'? 'linkOrderManage':'orderManage',
					query:{
						paymentType: item.status
					}
				}).catch(err=>{

				})
			}

		},
		getUnreadData(type) {
			return this.$store.state.manage.updateUnread[type];
		},

		hasOneShowingChild(children = [], parent) {
			const showingChildren = children.filter(item => {
				if (item.hidden) {
					return false;
				} else {
					// Temp set(will be used if only has one showing child)
					this.onlyOneChild = item;
					return true;
				}
			});
			// When there is only one child router, the child router is displayed by default
			if (showingChildren.length === 1) {
				return true;
			}
			// Show parent if there are no child router to display
			if (showingChildren.length === 0) {
				this.onlyOneChild = {...parent, path: "", noShowingChildren: true};
				return true;
			}
			return false;
		},
		resolvePath(routePath) {
			if (isExternal(routePath)) {
				return routePath;
			}
			if (isExternal(this.basePath)) {
				return this.basePath;
			}
			return path.resolve(this.basePath, routePath);
		},
		clearOrder(item){
			if(item.path == 'order' || item.path == 'linkOrder'){
				this.$store.commit("manage/changeResetForm", true);

			}
		}
	},
	mounted() {
	}
};
</script>

<style scoped lang="scss">
a:hover {
	text-decoration: none;
}

// 选中导航颜色
.nest-menu {
	&.collapse {
		::v-deep {
			.is-active .el-submenu__title {
				.isCollapse,
				.sub-el-icon,
				.el-icon-arrow-right {
					color: #ffd04b;
				}
			}
		}
	}
}

.orderIcon {
	position: absolute;
	right: 0px;
	display: inline-block;
	width: 100px;
	height: 100%;
	i{
		transform: rotate(180deg);
		&.down{
		transform: rotate(0deg);
	}
	}
}


.router-link-active{
	.active {
		color: #ffd04b;
	}
}
.paymentMenu {
	color: white;
	height: 50px;
	line-height: 50px;
	padding: 0 45px;
	min-width: 200px;
	font-size: 14px;

}
</style>
