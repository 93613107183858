import layout from "@/views/layout/index.vue";

const customIndex = () => import("@/views/index.vue");
const marketingDt = () => import("@/views/hrManagement/dtManagement/marketingDt/index.vue");
const SMSReminder = () => import("@/views/hrManagement/dtManagement/marketingDt/SMSReminder.vue");
const mkDashboard = () => import("@/views/hrManagement/dtManagement/mkDashboard/index.vue");
const externalChainManagement = () => import("@/views/hrManagement/dtManagement/externalChainManagement/index.vue");
const externalChainResourceManagement = () => import("@/views/hrManagement/dtManagement/externalChainManagement/resourceManagement.vue");
const externalChainBasic = () => import("@/views/hrManagement/dtManagement/externalChainManagement/basicInformation/index.vue");
const externalChainBasicProduct = () => import("@/views/hrManagement/dtManagement/externalChainManagement/basicInformation/productInfo.vue");
const externalChainBasicDetail = () => import("@/views/hrManagement/dtManagement/externalChainManagement/basicInformation/detail/index.vue");
const externalChainBulletinBoard = () => import("@/views/hrManagement/dtManagement/externalChainManagement/bulletinBoard.vue");
const externalChainProjects = () => import("@/views/hrManagement/dtManagement/externalChainManagement/projects.vue");
const externalChainProjectsList = () => import("@/views/hrManagement/dtManagement/externalChainManagement/projectsList.vue");
const statisticalManagement = () => import("@/views/hrManagement/dtManagement/statisticalManagement/index.vue");
const trainingMaterials = () => import("@/views/hrManagement/dtManagement/trainingMaterials/index.vue");
const performanceManagement = () => import("@/views/hrManagement/dtManagement/performanceManagement/index.vue");
const performanceEnter = () => import("@/views/hrManagement/dtManagement/performanceManagement/enter.vue");
const performanceRanking = () => import("@/views/hrManagement/dtManagement/performanceManagement/ranking.vue");
const performanceSelf = () => import("@/views/hrManagement/dtManagement/performanceManagement/self.vue");
export default {
	path: "/hrManagement",
	name: "hrManagement",
	component: layout,
	meta: {
		title: "人力资源管理",
		icon: "el-icon-s-cooperation"
	},
	children: [
		{
			path: "dtManagement",
			name: "dtManagement",
			component: customIndex,
			meta: {
				title: "部门管理"
			},
			children: [
				{
					path: "marketingDt",
					name: "marketingDt",
					component: marketingDt,
					redirect: {
						name: "mkDashboard"
					},
					meta: {
						title: "营销部"
					},
					children: [
						{
							path: "mkDashboard",
							name: "mkDashboard",
							component: mkDashboard,
							meta: {
								title: "Dashboard"
							},
							hidden: true
						},
						{
							path: "externalChainManagement",
							name: "externalChainManagement",
							component: externalChainManagement,
							meta: {
								title: "外链管理"
							},
							redirect: {
								name: "externalChainResourceManagement"
							},
							hidden: true,
							children: [
								{
									path: "externalChainResourceManagement",
									name: "externalChainResourceManagement",
									component: externalChainResourceManagement,
									meta: {
										title: "资源管理"
									}
								},
								{
									path: "externalChainBasic",
									name: "externalChainBasic",
									component: externalChainBasic,
									meta: {
										title: "基础信息"
									}
								},
								{
									path: "externalChainBasicProduct",
									name: "externalChainBasicProduct",
									component: externalChainBasicProduct,
									meta: {
										title: "产品生产信息"
									}
								},
								{
									path: "externalChainBasicDetail",
									name: "externalChainBasicDetail",
									component: externalChainBasicDetail,
									meta: {
										title: "基础信息详情"
									}
								},
								{
									path: "externalChainBulletinBoard",
									name: "externalChainBulletinBoard",
									component: externalChainBulletinBoard,
									meta: {
										title: "公告栏"
									}
								},
								{
									path: "externalChainProjects",
									name: "externalChainProjects",
									component: externalChainProjects,
									meta: {
										title: "Projects"
									}
								},
								{
									path: "externalChainProjectsList",
									name: "externalChainProjectsList",
									component: externalChainProjectsList
								}
							]
						},
						{
							path: "statisticalManagement",
							name: "statisticalManagement",
							component: statisticalManagement,
							meta: {
								title: "统计管理"
							},
							hidden: true
						},
						{
							path: "trainingMaterials",
							name: "trainingMaterials",
							component: trainingMaterials,
							meta: {
								title: "培训资料"
							},
							hidden: true
						},
						{
							path: "performanceManagement",
							name: "performanceManagement",
							component: performanceManagement,
							redirect: { name: "performanceEnter" },
							meta: {
								title: "绩效管理"
							},
							hidden: true,
							children: [
								{
									path: "performanceEnter",
									name: "performanceEnter",
									component: performanceEnter,
									meta: {
										title: "绩效录入"
									},
									hidden: true
								},
								{
									path: "performanceRanking",
									name: "performanceRanking",
									component: performanceRanking,
									meta: {
										title: "绩效排名"
									},
									hidden: true
								},
								{
									path: "performanceSelf",
									name: "performanceSelf",
									component: performanceSelf,
									meta: {
										title: "个人绩效"
									},
									hidden: true
								}
							]
						}
					]
				},{
          path: "SMSReminder",
          name: "SMSReminder",
          component: SMSReminder,
          redirect: {
            name: "SMSReminder"
          },
          meta: {
            title: "短信提醒"
          },
        }
			]
		}
	]
};
