// lang/en.js
import enLocale from "element-ui/lib/locale/lang/en";

export default {
	systemName: "Management",
	navbar: {
		dashboard: "Home",
		logOut: "Logout",
		profile: "Personal Center",
		theme: "Change Theme",
		size: "Layout Size",
	},
	header: {
		title: "title",
		logIn: "logIn",
		usernameTips: "usernameTips",
		passwordTips: "passwordTips",
	},
	leftSidebar: {
		homePage: "Home Page",
		basicSetting: "Basic Setting Management",
		country: "Country Management",
		timedTask: "Timed Task",
		authority: "Authority Management ",
		accountAuthority: "Account authority management",
		express: "Express management",
		backlogJump: "To-do jump management",
		language: "Language management",
		crowd: "Crowd management",
		trackingTimeManagement: "Tracking Time management",
		commodity: "Commodity Management",
		fullCustom: "Full Custom Commodity Management",
		project: "Project Management",
		productCategory: "Product Category",
		colourAtla: "Color Card",
		iconLibrary: "Icon library",
		semiCustom: "Semi-custom commodity management",
		classify: "Semi-custom classification",
		colourAtla2: "Color card library",
		property: "Attribute library Management",
		categoryLibrary: "Category Warehouse Management",
		commodityWarehouse: "Commodity Warehouse Management",
		addEditCommodity: "Add/Modify Commodities",
		designSystem: "Design System Management",
		art: "Art Management",
		color: "Color Management",
		templateManage: "Template Management",
		viewDatabase: "View Database Management",
		sharePictures: "Shared Picture Library",
		allianceBusiness: "Franchisee Management",
		uspp: "USPP Management",
		websiteProject: "Website Project Management",
		monthInvoice: "Month Invoice",
		pages: "Page Management",
		relation: "Contact Management",
		faq: "FAQ Management",
		resource: "Resource Management ",
		agent: "Agent Management",
		subscriptionQuery: "Subscription Query Management",
		agentClause: "Agent Terms",
		agentManagement: "Agent Management",
		agentWebsite: "Agent Website Management ",
		franchiser: "Dealer Management",
		otoPage: "oto Page Management",
		setMeal: "Package Management",
		extraPackage: "Extra Package Management",
		dealerAuthority: "Dealer Authority Management ",
		dealerRole: "Dealer role management",
		productOpinion: "Product opinion management",
		newsContent: "News content management",
		referral: "Referral",
		referralStatistics: "Referral statistics ",
		virtualA: "Virtual A",
		practicalA: "Actual A",
		finished: "Ended",
		taskCheck: "Task Check",
		taskAssessment: "Task Assessment",
		referralConfiguration: "Referral Configuration Management",
		referralLevel: "Referral Level Management",
		integralWithdrawal: "Points Withdrawal",
		inquiryOrder: "Inquiry Order Management",
		inquiry: "Inquiry",
		inquiryDetails: "Inquiry Details",
		order: "Order",
		drawingUpload: "Drawing Upload",
		orderDetails: "Order Details",
		inquiryList: "Inquiry List",
		linkOrder: " Link Order",
		directOrder: "Direct Order",
		DOrder: "3D Order Management",
		allocationConfiguration: "allocation Configuration",
		manufactureOrder: "Production Order Management",
		factoryQuotation: "Factory Quotation Parameter Management",
		projectQuotation: "Quotation Project Management",
		projectArguments: "Quotation Parameter Configuration",
		produceSingleList: "Production List",
		customsDeclaration: "Customs Declaration",
		shipments: "Delivery",
		imageDownload: "Download Artwork ",
		orderQuantity: "Order Quantity",
		orderStatus: "Order Status",
		actualShipments: "Actual Shipment Quantity",
		shipAddress: "Shipment Address",
		acceptor: "Acceptor",
		statusDropdownBox: "production order status drop-down box",
		scheduleDropdownBox: "webbing production progress drop-down box",
		financialProgressDropdownBox: "financial progress drop-down box",
		purchaseOrderStatus: "purchase order status drop-down box",
		financialProgress: "Financial Progress",
		offerOrNot: "Quotation or not",
		creationTime: "Creation Time",
		deliveryTime: "Delivery Time",
		salesman: "Salesman",
		invoiceNumber: "Invoice No",
		orderNumber: "Order Number",
		addInvoice: "Invoice (Add)",
		invoiceSkipPrinting: "Skip Printing of Invoice Number",
		listInvoice: "List Invoice ( Whether to display)",
		deleteProblemList: "Batch delete problem lists",
		shippingList: "Delivery List (Delivery Time)",
		deliveryTimeState: "Delivery List (Order Status)",
		buyingSituation: " Purchasing status",
		questionnaireTopMenu: "Question list top menu (click)",
		backOrder: "Deferred order top (click)",
		giftOrder: "Gift order",
		printDispatchBill: "Print delivery bill",
		addDispatchBill: "Invoice Add (page)",
		purchasePrinting: "Purchase Print",
		buyerBankAccountInformation: "Buyer Bank Account Information",
		buyerInformation: "Buyer Information",
		questionnaire: "Question List",
		publicParameters: "Public Parameter Management",
		parameterType: "Parameter Type Management",
		ProductionOrderStatistics: "Production order statistics",
		kunshanProduct: "Kunshan production list statistics",
		changdeProduct: "Changde production list statistics",
		ribbonstatistics: "Ribbon factory statistics",
		Purchasestatistics: "Purchase order statistics",
		purchasingAgent: "Purchaser Management",
		statistics: "Production Order Statistics",
		favourable: "Discount Management",
		discountCoupon: "Coupon Specifications",
		discountCouponList: "User Coupon List",
		paymentCenter: "Payment Center Management",
		userManage: "User Management",
		memberInformation: " Member Information Management",
		memberOrder: "Membership Level Management",
		affiliateMarketing: "Affiliate Marketing External Link Management",
		basicInformation: "Basic Information Entry",
		blogger: "Blogger Management",
		classicCase: "Classic Case Application",
		orderSubmission: "Order Submission Application",
		sponsoredLinks: "Sponsored Link Management",
		statisticsQuery: "Statistics Query",
		finance: "Financial Management",
		profitCenter: " Profit center",
		waybillData: "waybill data",
		profitData: "profit data",
		abnormalWaybill: "abnormal waybill",
		refund: "refund management",
		export: "financial UF export",
		orderExport: "Order export",
		financialOffline: "Financial offline collection",
		contentManagement: "content management",
		websiteStyle: "website style",
		template: "template",
		subjectColor: "subject color",
		logo: "logo library",
		video: " video library",
		labelManagement: "label management",
		customProducts: "custom product",
		classOfProduct: "custom product category",
		categoricalAttributes: "custom product category attribute",
		linkedProducts: "Linked Products",
		inventory: "Inventory Products",
		selfOperatingPlatform: "Self-Operating Platform Management",
		websiteContent: "Website Content Management",
		websitePrice: "Website Price Control",
		xuanzexiangmu: " Select Project",
		xuanzeyuyan: "Select Language",
		tuichu: "Exit",
		xiugaimima: "Change Password",
		switchAccount: "Switch Account",
		roleManagement: "Role Management",
		chakan: "View, Delete",
		shengchandanyemianquanxian: "Permission to Produce Single Pages",
		zhidinggongchang: "Designated factory - edit",
		youjianchaosong: "Cc the shipper - edit",
		dingdanzongshuliang: "Total order quantity - edit",
		yangpinshuliang: "Sample quantity - edit",
		jiaohuoriqi: "Delivery date - edit",
		dingdanqingkuang: "Order status - edit",
		quedingshengchanZong: "Confirm production (total) - edit",
		chanpinleixing: "Product type - edit",
		caigopeijiandananniu: "Purchase order button - edit",
		zhuancaigodananniu: "Transfer to purchase order button - edit",
		dingdanzhuangtai: "Order status (parameter) - edit",
		xudanhao: "Continuation order number (parameter) - Edit",
		shuliang: "Quantity (parameter) - edit",
		xiugaizhidinggongchang: "Modify the specified factory (parameter)",
		youjianchaosongEdit: "Cc sender (parameter) - edit",
		caizhi: "Material (parameter) - edit",
		gongyi: "Process (parameter) - edit",
		gongyicolor: "Process color (parameter) - edit",
		chanpinsize: "Product size (parameter) - edit",
		chanpinsizestr: "Product size Str (parameter) - edit",
		hodu: "Thickness (parameter) - edit",
		diandu: "Electroplating (parameter) - edit",
		yinshua: "Printing (parameter) ) - edit",
		yinshuacolor: "printing color (parameter) - edit",
		cemiandiaoke: "side engraving (parameter) - edit",
		bian: "edge (parameter) - edit",
		paoguang: "Polishing (parameter) - edit",
		beimo: "Back film (parameter) - edit",
		qita: "Others (parameter) - edit",
		qitaNumber: "Other numbers (parameter) - edit",
		peijian: "Accessories (parameters) - edit",
		peijianNumber: "Accessories quantity (parameters) - edit",
		baozhuang: "Packaging (parameters) - edit",
		muju: "Mold (parameters) - Edit",
		addshengchanjiage: "Add production price button (sub) - edit",
		delshengchanjiage: "Delete production price (sub) - edit",
		shengchanjiageNume: "Production price (name) - edit",
		shengchanjiageNumber: "Production price (quantity)-edit",
		shengchanjiagePrice: "Production price (unit price)-edit",
		shengchanjiageMuJu: "Production unit price (mold fee)-edit",
		shengchanjiageQita: "Production price (other )-edit",
		quedingshengchanZi: "Confirm production (sub)-edit",
		cancelOrder: "Cancel order, stop production (sub)-edit",
		reasonForDelay: "Delay reason (sub)-edit",
		businessModificationContentZi: "Business modification content (sub)-edit",
		truePrice: "True price-view",
		dimensionWidth: "Dimension width (parameter)-edit",
		dimensionLength: "Dimension length (parameter) -Edit",
		addOrderBut: "Purchase - Add Order Button - View",
		orderDetailsArt: "Purchase - Order Details - Art Picture - View",
		POFImageUploading: "Purchase - POF Artwork Upload and Download - View ",
		artworkImgUploading: "Purchase-Artwork image upload-view",
		affirmPause: "Purchase-confirm and suspend purchase-view",
		purchaseProductDetails: "Purchase-purchase product details-view",
		caigoTruePrice: " Purchasing - Real Price - View",
		expressPictures: "Purchasing - Express Picture - View",
		purchasingNotes: "Purchasing - Purchase Notes - View",
		financialNotes: "Purchase - Financial Notes - View",
		businessModificationContent: "Purchase-Business Modification Content-View",
		procurementSchedule: "Purchase-Procurement Progress-View",
		caiGoFinancialProgress: "Purchase-Financial Progress-View",
		waybillInformation: "Purchase- Waybill Information-View",
		caiGoOrderDetailsArt: "Purchase- Order Details-art Picture-Edit",
		artworkImg: "Purchase- artwork Picture-Edit",
		reviewPurchasePrice: "Purchase- Review Purchase Price-View",
		businessModificationContentBianJi: "Purchase- Business Modification Content - Edit",
		affirmPauseBianJi: "Purchase - Confirm and Suspend Purchase - Edit",
		caigoTruePriceBianJi: "Purchase - True Price - Edit",
		purchasingNotesBianJi: "Purchase - Purchase Notes - Edit",
		procurementScheduleBianJi: "Purchase-Purchase Progress-Edit",
		caiGoFinancialProgressBianJi: "Purchase-Financial Progress-Edit",
		waybillInformationBianJi: "Purchase-Waybill Information-Edit",
		reviewPurchasePriceBianJi: "Purchase-Review Purchase Price-Edit",
		redoOrderButton: "Purchase-Redo Order Button - View",
		remark: "Remarks (sub)-Edit",
		shipAddressBianJi: "Shipment Address-Edit",
		mountings1: "Accessories(1) - Edit",
		mountings2: "Accessories (2) - Edit",
		shengchanjiage: "Production Price - View",
		totalProductionOrderBtn: "Total Production Order Redo Button - Edit",
		subproductionOrderBtn: "Subproduction Single redo button - edit",
		allAccessoriesFactoryNo: "All accessories factory order number - edit",
		ribbonProductionNo: "ribbon production order number - edit",
		factorySchedule: "factory schedule - edit",
		ribbonProductionOrder: "Ribbon Production Order - View",
		purchaseAccessoriesList: "Purchase Accessories List - View",
		subpurchaseOrder: "Subpurchase Order - View",
		redoOrder: "Redo Order - View",
		subfixedProduction: "Sub Confirm Production-View",
		reviewProductionPriceButBianJi: "Review Production Price Button-Edit",
		reviewProductionPriceBut: "Review Production Price Button-View",
		confirmPurchase: "Purchase-Confirm Purchase-Edit",
		sampleStatus: "Sample Status-Edit",
		natureOrder: "Order Nature-Edit",
		orderProductionStatus: "Order Production Status-Edit",
		modifyBuyerType: "Purchase-Modify Buyer and Purchase Type",
		webbingPopover: "Webbing Pop-up window to confirm production-edit",
		caiGoDanyemianquanxian: "Purchase order page authority",
		orderDetailsArt2: "Order details-art image",
		PDFImageUploading: "PDF artwork upload and download",
		caiGoArtworkImgUploading: "Artwork Upload pictures",
		confirmSuspendPurchases: "Confirm and suspend purchases",
		caiGoPurchaseProductDetails: "Purchase product details",
		trueprice2: "True price",
		caiGoExpressPictures: "Express pictures",
		caiGoPurchasingNotes: "Purchase notes - View",
		financialNotesView: "Financial Notes-View",
		businessModificationContentView: "Business Modification Content-View",
		procurementProgressView: "Purchase Progress-View",
		financialProgressView: "Financial Progress-View",
		waybillInformationView: "Waybill Information-View",
		orderdetailsEdit: "Order Details-art Picture-Edit",
		artworkImageEdit: "artwork Picture-Edit",
		reviewPurchasePriceView: "Review Purchase Price-View",
		businessModificationContentEdit: "Business Modification Content-Edit",
		confirmSuspendPurchasesEdit: "Confirm and Suspend Purchases-Edit",
		truepriceEdit: "True Price-Edit",
		caiGoPurchasingNotesEdit: "Purchasing Notes-Edit",
		procurementProgressEdit: "Purchase Progress - Edit",
		financialProgressEdit: "Financial Progress - Edit",
		waybillInformationEdit: "Waybill Information - Edit",
		reviewPurchasePriceEdit: "Review Purchase Price - Edit",
		redoOrderButtonView: "Redo Order Button - View ",
		addOrderButView: "Add Order Button - View",
		printPageView: "Purchase Order Print Page - View",
		financialNotesEdit: "Financial Notes - Edit",
		PDFImageUploadingEdit: "PDF Artwork Upload and Download - Edit",
		purchaseCompletedView: "Purchase Completed - View",
		purchaseCompletedEdit: "Purchase Completed - Edit",
		caiGoExpressPicturesEdit: "Express Pictures - Edit",
		confirmPurchaseEdit: "Confirm Purchase - Edit",
		caiGoPurchaseProductDetailsEdit: "Purchase Product Details - Edit",
		sampleStatusView: "Sample Status - View",
		orderPurchaseStatus: "Order Purchase Status - Edit",
		modifyBuyerType2: "Modify Buyer and Purchase Type",
		addMenu: "Add a first level menu",

		configurationManagement: "Configuration Management",
		projectConfiguration: "Project Configuration",
		projectTypeConfiguration: "Project Type configuration",

		editPermission: "Edit Permission",
		delPermission: "Remove Permissions",
		addPermission: "Add Permission",

		detailsPermission: "Details Permissions",
		printPermission: "Print Permission",
		linkArtworkPermission: "Artwork link permission",
		messageAlertPermission: "Message Prompts Permissions",
		imageUploadingPermission: "Artwork Upload Permission",
		writePermission: "Permission to send Messages",

		userNotesPermission: "User Remark Permissions",
		remarksInquiryPermission: "Inquiry Remark Authority",
		changeSalesmanPermission: "Change the salesperson permissions",
		orderStatusEdit: "Order Status editing",
		inquiryStatusEdit: "Inquiry status edit",

		imageUploadingEdit: "Upload and edit the artwork",
		deleteArtwork: "Delete artwork",
		artworkSwitchLock: "Artwork switch lock",

		invoicePermission: "Authority of invoice",
		sanDOrdersPermission: "3D order permissions",
		addRefundOrderPermission: "Add refund permission",
		equipmentPermission: "Device permissions",
		makeInvoicesPermission: "Permission to make invoice",

		userInformationEditPermission: "User information edit permission",
		orderStatusEditPermission: "Order status edit permission",
		payStatusEditPermission: "Payment status edit permission",
		orderInfoEditPermission: "Order information edit permission",
		transportInformationEditPermission: "Transportation information editing permission",
		siteEditPermission: "Address edit permission",

		viewAllOrdersPermission: "Query all order permissions",
		gmailManage: "Gmail management",
		gmailCenter: "Gmail Center",
		gamilGroup: "Member Group",
		gmailSign: "Gmail Sign",
		gmailSetting: "Gmail Setting",
	},
	inquiryPage: {
		dealeSelfOperatedPlatformrs: "Self Operated Platform",
		dealer: "O2O Dealer",
		allianceBusiness: "USPP Franchisee",
		timeFilter: "Time Filter",
		date: "Date",
		reset: "Reset",
		add: "Add",
		delete: "Delete",
		drawingUpload: "Drawing Upload",

		uploadOrder: "Upload Order",
		orderNo: "Order ID / Email",
		neiRong: "Please enter the content",
		price: "Price",
		number: "Waybill Number",
		orderState: "Order Status",
		pleaseSelect: "please select",
		orderPayoutStatus: "Order payment status",
		hoFu: "postpaid order",
		weiZhiFu: "Unpaid order",
		yiZhiFu: "paid order",
		yiTuiKuang: "Refunded order",
		CaliforniaOrder: "View California orders only",
		operationPlatform: "operation platform",
		xianShiShuLiang: "Display quantity",
		chaXun: "Query",

		addTuiKuanDan: "Add a refund form",
		manufactureOrder: "production order",
		purchaseOrders: "purchase order number",
		ueserRemark: "User remarks",
		fuJian: "attachment",
		zhuangTai: "State",
		daiQueRen: "Unconfirmed",
		yiQueRen: "acknowledged",
		weiTongGuo: "not pass",
		unitCost: "Drawing cost unit price",
		toolingCharge: "Drawing and mold fee",
		additionalCharge: "Drawing surcharge",
		productParameter: "Artwork product parameters",
		orderInvoice: "Make order invoice",
		optionDate: "option date",
		quantity: "Quantity:",
		unitPrice: "Unit price:",
		toolingCharge2: "Tooling charge",
		deliveryDiscount: "Delivery discount:",
		aggregate: "Aggregate:",
		describe: "Describe:",
		remark2: "Remark:",
		definiteGeneration: "Definite generation:",
		addInvoice: "(The invoice cannot be modified after confirmation)",
		submit: "Submit",
		fanHui: "Return",
		customerName: "Customer Name",
		orderTotal: "Order Total",
		invoiceProgress: "Invoice Progress",
		business: "Business",
		xiangmuliebiao: "Item list：",
		xuanzexiangmu: "Select Project",
		guanjianzi: "Keyword：",
		time: "Time：",
		to: "to",
		startDate: "Start Date",
		dateClosed: "Date Closed",
		yewuyuan: "Saleman：",
		search: "Search",
		invoiceNumber: "Invoice No",
		orderNumber: "Order Number",
		productClass: "Product Class",
		remark: "Remark",
		sanDModeling: "The amount of 3d modeling required",
		creationTime: "Creation Time",
		operation: "Operation",
		edit: "Edit",
		suoxushuliang: "Quantity Required",
		videoXinXi: "Video Information",
		addVideo: "Add Video",
		cancel: "Cancel",
		confirm: "Confirm",
		xinJian: "New",
		sheJiZhong: "Design",
		sheJiWanCheng: "Design Completed",
		aweek: "Last week",
		onemonth: "Last month",
		trimester: "Last three months",
		dingdanhao: "Order number/email/artwork name",
		cuikuanTime: "Call time",
		preview: "Preview",
		uploading: "Uploading",
	},

	refundForm: {
		newRefundOrder: "Manager Approval",
		managerApproved: "Manager approved",
		adminApproved: "Admin approved",
		rejected: "Approval not passed",
		refunded: "Partial refund",
		tuikuanOrder: "Refund Order",
		shenpitongguo: "Approved",
		shenpi6: "refunded",
		quanbu: "All",
		shenpi1: "in approval",
		shenpi2: "Manage Approved",
		shenpi3: "Admin Approved",
		shenpi4: "Manage Approval failed",
		shenpi5: "Admin Approval not passed",
		fapiao: "Invoice number/email..",
		bufentuikuan: "Partial refund",
		quanbutuikuan: "Full refund",
		qingxuanze: "Please select",
		name: "Name",
		email: "Email",
		phone: "Phone",
		fapiaohao: "Invoice number",
		tuikuanyuanyi: "Reason for refund",
		department: "Responsible Department",
		tuikuanleixing: "Refund Type",
		tuikuanjine: "refund amount",
		qingshuru: "Please enter the amount",
		zhifufanshi: "Payment method",
		yuanyibeizhu: "Reason remarks",
		shenhestate: "Audit status",
		shenPistate: "Approval Status",
		baocun: "Save",
		quxiao: "Cancel",
		zhizuotuikuandan: "Create a refund form",

		operate: "Operate",
		createTime: "Time",
		orderID: "Order ID",
		customers: "Customers",
		salesman: "Salesman",
		jingli1: "Manager approval in progress",
		jingli2: "admin approved",
		jingli3: "Approval failed",
		chongxin1: "Under re approval",
		chongxin2: "Manager approved",
		tongyi: "consent",
		tuikuanstate: "Refund Status",
		orderDetails: "Order Details",
		jiaoqiyanwu: "Delivery Delay",
		sejiyaoqiu: "Design modification requirement",
		tuishui: "Drawback",
		zhiliangwenti: "Quality Problem",
		quxiaoOrder: "Cancellation of order",
		qita: "Other",
	},
	uploadDraft: {
		caozuorizhi: "Operation Log",
		addProductInformation: "Add product information",
		name: "Name",
		invoiceMumber: "Invoice number",
		webLinks: "Affiliated website link",
		userEmail: "Customer email",
		uploadArtwork: "Upload artwork and customized information",
		upload: "Add new product",
		upload2: "(Click here to upload)",
		defaultPicture: "Add additional fee",
		tariffQuantity: "Tariff quantity",
		productionCapture: "Captured by production orders",
		artSwitchLock: "Draft switch lock",
		productClass: "Product category",
		alternateDrawing: "Replace current artwork",
		addDrawing: "Add artwork",
		addText: "Add customized information (please fill in the unit price before discount)",
		copy: "Copy Item",
		quantity: "Number",
		price: "unit price",
		toolingCharge: "Mold fee",
		extraFee: "Extra Fee",
		deliveryDiscount: "Delivery discount",
		aggregate: "Total",
		xuanzefuzhi: "Select the copied item",
		describe: "describe",
		parameterFormat: "Parameter format: key: value, no spaces after colon, carriage return and line feed (English format)",
		remark: "Remark",
		submit: "Submit",
		staging: "Staging",
		sendEmail: "send mail",
		tishi: "prompt",
		tishiText: "You have a cache data, please confirm if it is displayed?",
		quxiao: "Cancel",
		queding: "confirm",
		shiyin: "silk screen",
		beimo: "Dorsal membrane",
		printing: "print",
		medallionRibbon: "Equipped with medal webbing",
		beginMs: "Open tax exemption",
		msZm: "Tax exemption certificate",
		uploadMsZm: "Upload Tax Exemption Certificate",
		gqTime: "Expiration",
	},

	Invoice: {
		MoldSetupFee: "Mold/Setup Fee",

		invoice: "INVOICE",
		PONumber: "PO Number",
		reference: "Reference",
		HSCODE: "HS CODE",
		PURCHASEINVOICE: "PURCHASE INVOICE",
		productPrintNo: "productPrint No",

		BILLTO: "BILL TO",
		SHIPTO: "SHIP TO",
		ProductDescription: "Product Description",
		total: "Total",
		PaymentStatus: "Payment Status",
		checkInfo: "Check Info",

		bankInfo: "Bank Info",
		payOnline: "Pay Online",
		checkPay: "Check: Pay and Mail to Info Below",
		attentionPlease: "Remember",
		directorOperations: "Director of Operations",

		signature: "Signature",
		nodesText: "If you are making payment through the bank, please make sure to include your",
		nodesText2: "in the memo.",
		thankbusiness: "Thank you for your business!",
		paid: "PAID",

		unpaid: "UNPAID",
		payInstall: "Partial payment, wait for the balance.",
		remember: `For Offshore Account and Foreign Currency Account which is used by foreign clients to remit to our bank,the use of funds should be 'Payment for Goods', or leave it blank.`,
		extraText: [
			"",
			"Rush Fee",
			"Silkscreen Printed Fee",
			"Back Mold Fee",
			"Printed Charge",
			"Zinc Die Casting Material Cost",
			"Extra Freight",
			"Licensed Products Fee",
			"Quantity",
		],
		TaxPrice: "Tax Price",
		discountedAmount: "Discounted Amount",

		card: "1. Credit Card: VISA / Mastercard / AMEX, and Discover",
		checkFollow: "2. Please find our check information as follows: ",
		bankNote:
			"Note: If you would like to pay in a different currency other than EUR, please get in touch with our customer service rep.",
		invoiceDate: "Invoice date",
		invoiceNumber: "Invoice number",
		topic: "Topic: Finance advice",
		excluding: "Subtotal excluding",
		mention: "When you make the payment, please mention invoice number. Thank you!",
		phone: "Phone",
		email: "Email",
		from: "From",
		to: "To",
		attn: "Attn",
		lNVOICE: "INVOICE",
		PROFORMA: "PROFORMA INVOICE",
		invoiceNo: "Invoice No.",
		date: "Date",
		paymentMethod: "Payment Method",
		terms: "Terms",
		hsCode: "hsCode",
		title: "Title",
		no: "No",
		artwork: "Artwork",
		item: "Item",
		description: "Description",
		qty: "Qty",
		unitPrice: "Unit Price",
		moldFee: "Mold Fee",
		extraFee: "Extra Fee",
		amount: "Amount",
		subtotal: "Sub Total",
		excl: "Excl",
		apoShippingFee: "Apo Shipping Fee",
		taxPrice: "Tax Price",
		couponAdditionalCosts: "Additional Costs ",
		firstOrderCodeDisc: "First Order Discount ",
		promoCode: "Promo Code ",
		specialCode: "Special Group Offers",
		custompensCode: "$1 for 20pcs Pens",
		additionalCode: "$1 for a $75 Voucher",
		pointsDeduct: "Points Deduct ",
		points: "Points",
		giftVouchers: "Gift Vouchers",
		couponDiscount: "Coupon Discount ",
		orderDiscount: "Order Discount ",
		extraDiscount: "Extra Discount ",
		finalPrice: "Final Price",
		finalTotalPrice: "Final Total Price",
		estimatedShipDate: "Estimated Ship Date",
		notes: "Notes",
		name: "Name",
		check: "Check",
		APOFPOAddress: "APO/FPO Address",
		Address: "Address",
		GrandTotal: "Grand Total",
		AmountPaid: "Amount Paid",
		BalanceDue: "Balance Due",
		UserLevelDiscount: "User Level Discount ",
	},
	Quotation: {
		free: "FREE",
		company: "Company",
		off: "OFF",
		additionalCosts: "Additional Costs",
		firstOrderDiscount: "First Order Discount",
		specialGroup: "Special Group Offers",
		pointsDeduct: "Points Deduct",
		giftVouchers: "Gift Vouchers",
		couponDiscount: "Coupon Discount",
		goldVipMember: "Gold Vip Member",
		extraDiscount: "Extra Discount",
		userLevelDiscount: "User Level Discount",
		points: "Points",

		title: "QUOTATION",
		to: "To",
		from: "From",
		name: "Name",
		email: "Email",
		telephone: "Telephone",
		country: "Country",
		oid: "Order ID",
		date: "Quotation Date",
		valid: "Valid Until",
		contacts: "Contacts",
		currency: "Currency",
		no: "No.",
		item: "Item",
		artwork: "Artwork",
		description: "Description",
		qty: "Quantity",
		unitPrice: "Unit Price",
		moldFee: "Mold Fee",
		extraFee: "Extra Fee",
		apoShippingFee: "Apo Shipping Fee",
		taxPrice: "Tax Price",
		amount: "Amount",
		remarks: "Remarks",
		total: "Total",
		titleTerms: "Terms & Conditions:",
		first: "1. Free artwork and Free Shipping to USA / Canada / Europe.",
		second: "2. Above information is not an invoice and only an estimate of services/goods described above.",
		third: "3.The production will start on the date we receive your proof approval and payment, please pay this link for your order based on the approved proof. (Payment Link:",
		end: "Thank you for your business!",
		tel: "Tel",
		hours: "Office Hours",
		chat: "Live Chat",
		address: "USA Address",
		hoursItem: "Monday - Friday 8:30 AM to 5:30 PM Pacific Time (PT)",
		chatItem: "24 hours Monday through Friday",
		addressItem: "20829 Valley Blvd. Walnut, CA 91789",
	},
	draft: {
		itemName: "Item Name",
	},
	emailManage: {
		inbox: "Inbox",
		outbox: "Sent",
		writeEmail: "Compose",
		emailSign: "gmail sign",
		selectEmail: "Select Gmail",
		addEmail: "Add Gmail",
		emailName: "Gmail Name",
		emailAccount: "Gmail Account",
		emailPassword: "Gmail Password",
		emailMembers: "Gmail Members",
		addMember: "add member",
		emailList: "Gmail List",
		deleteGmail: "delete gmail",
		updateGmail: "update gmail",
		users: "all user",
		gmailUserName: "member name",
		sender: "sender",
		Sign: "Sign:",
		sign: "Sign Name:",
		save: "Save Setting",
		save2: "save",
		signDesc: "(Attached at the end of all outgoing emails)",
		createSign: "create new sign",
		manageSign: "Manage Sign",
		changeEmail: "Change Gmail",
		editSignName: "Change signature name",
		defaultSign: "Default signature settings",
		forNewEamil: "For new gmails",
		forReply: "Used for reply/forwarding",
		signSetting: 'Insert a signature before the quoted text in the reply email and remove the "--" line before it.',
		sendTo: "send to",
		forwardTo: "forward to",
		ccTo: "cc to",
		replay: "Reply",
		replyAll: "Reply all",
		relay: "Forward",
		relayAll: "Forward all",
		send: "send",
		attachment: "attachment",
		memberId: "US office member ID",
		memberName: "US office member name",
		salesmanNumber: "Assign the number of salesman",
		addSalesman: "Add salesmans",
		salesman: "salesmans",
		mySalesman: "my salesmans",
		salesmanName: "salesman name",
		ModifyingInformation: "Modifying Information",
		reset: "reset",
		cancel: "Cancel",
		confirm: "Confirm",
		create: "Create",
		delete: "delete",
		edit: "edit",
		index: "index",
		showDetail: "show detail",
		placeholder: "search US office member name",
		Recipient: "Recipient:",
		expand: "expand",
		retract: "retract",
		newEmail: "new gmail",
		recipient: "recipient",
		subject: "subject",
		makeCopy: "cc gmail",
	},
	member: {
		member1: "Silver Member",
		member4: "Gold VIP Member",
		member5: "Platinum VIP Member",
	},
	finance: {
		offlineCollectionFinancial: "Offline Collection - Financial",
		offlineCollectionSalesman: "Offline Collection - Salesman",
	},
	kpiManage: {
		basicSetting: "Customer service KPI management",
		completionRate: "Completion KPI Management",
		completionRateOne: "Personal Completion KPI report",
		completionRateAll: "Total Compltetion KPI report",
		repurchaseRate: "KPI management for repurchase",
		repurchaseRateAll: "Total KPI management for repurchase",
		repurchaseRateOne: "Personal KPI management for repurchase",
		commentRate: "Upload positive feedback data",
		kpiRuleManagement: "KPI rule management",
		historicalRankingRecord: "Historical ranking record",
		comprehensiveHistoricalRanking: "Comprehensive historical ranking record",
		individualHistoricalRanking: "Personal historical ranking record",
	},
	treeData: {
		name: "Product category management",
	},
	login: {
		switchAccount: "Switch Account",
		bindAccount: "Bind Account",
		bindAccountTips: "Please bind a general backend account",
		selectAccount: "Select Account",
		selectAccountTips: "Currently, multiple accounts are bound to the general backend, please double-click to select",
		back: "Back",
		addBinding: "Add Binding",
		binding: "Binding",
		ok: "OK",
		generalBackendAccount: "General Backend Account",
		generalBackendPassword: "General Backend Password",
		accountErrorTips: "General Backend Account Is Required.",
		passwordErrorTips: "General Backend Password Is Required.",
	},
	...enLocale,
};
