import gaLocale from 'element-ui/lib/locale/lang/en'
export default {
	Invoice: {
		MoldSetupFee:'Fotha Mold/Socraithe',

		invoice:"sonrasc",
		PONumber:"Uimhir PO",
		reference:"Tagairt",
		HSCODE:"CÓD HS",
		PURCHASEINVOICE:"Sonrasc CEANNACH",
		productPrintNo:"táirgePriontáil Uimh",
		BILLTO:"BILLE",
		SHIPTO:"SEOL CHUIG",
		ProductDescription:"Cur síos ar an Táirge",
		total:"Iomlán",
		PaymentStatus:"Stádas Íocaíochta",
		checkInfo:"Seiceáil Eolas",
		bankInfo:"Eolas Bainc",
		payOnline:"Íoc Ar Líne",
		checkPay:"Seiceáil:Íoc agus Ríomhphost chuig an Eolas thíos",
		attentionPlease:"Cuimhnigh",
		directorOperations:"Stiúrthóir Oibríochtaí",
		signature:"Síniú",
		nodesText:"Má tá íocaíocht á déanamh agat tríd an mbanc, déan cinnte go gcuirfidh tú do chuid",
		nodesText2:"sa mheamram.",
		thankbusiness:"Go raibh maith agat as do ghnó!",
		paid:"ÍOCTHA",
		unpaid:"UNPAID",
		payInstall:"Íocaíocht pháirteach, fan ar an iarmhéid.",
		remember:" Maidir le Cuntas Eischósta agus Cuntas Airgeadra Coigríche a úsáideann cliaint ón gcoigríoch chun a chur ar aghaidh chuig ár mbanc, ba cheart go mbeadh úsáid cistí mar 'Íocaíocht ar Earraí', nó é a fhágáil bán.",
		extraText:[
			"",
			"Táille Rush",
			"Táille Clóis Síogaire Priontáilte",
			"Táille Mhúirín Ar Ais",
			"Muirear Clóbhuailte",
			"Sinc Die Casting Ábhar Costas",
			"Lastais Breise",
			"Táille Táirgí Ceadúnaithe",
			"Méid"
		],
		TaxPrice:"Praghas Cánach",
		discountedAmount:"Méid Lascaine",


		card:"1. Cárta Creidmheasa: VISA / Mastercard / AMEX, agus Discover",
		bankNote:"Nóta: Más mian leat íoc in airgeadra eile seachas EUR, téigh i dteagmháil lenár n-ionadaí seirbhíse do chustaiméirí le do thoil.",
		checkFollow:"2. Faigh ár n-eolas seiceála le do thoil mar seo a leanas:",
		mention:"Nuair a dhéanann tú an íocaíocht, luaigh uimhir an tsonraisc. Go raibh maith agat!",
		topic:"Ábhar: Comhairle airgeadais",
		invoiceDate:"Dáta an tsonraisc",
		invoiceNumber:"Uimhir sonrasc",
		excluding:"Fo-iomlán gan áireamh",
		phone: "Fón",
		email: "Ríomhphost",
		from: "Ó",
		to: "Chun",
		attn: "Attn",
		lNVOICE: "sonrasc",
		PROFORMA:"Sonrasc PROFORMA",
		invoiceNo: "Sonrasc Uimh",
		date: "Dáta",
		paymentMethod: "Modh íocaíochta",
		terms:"Téarmaí",
		title: "Teideal",
		no: "Níl",
		artwork: "Saothar ealaíne",
		item: "Mír",
		description: "Cur síos",
		qty: "Ceathair",
		unitPrice: "Praghas aonaid",
		moldFee: "Táille Mhúirín",
		extraFee: "Táille Breise",
		amount: "Méid",
		subtotal: "Fo-iomlán",
		apoShippingFee: "Táille Loingseoireachta Apo",
		taxPrice: "Praghas Cánach",
		couponAdditionalCosts:"Costas Breise",
		firstOrderCodeDisc: "Lascaine Chéad Ordú",
		promoCode: "Cód promo",
		specialCode:"Tairiscintí Grúpa Speisialta",
		custompensCode:"$1 le haghaidh pinn 20 ríomhaire",
		additionalCode:"$1 ar Dhearbhán $75",
		pointsDeduct: "Asbhaint Pointí",
		points:"Pointí",
		giftVouchers: "Dearbháin Bronntanais",
		couponDiscount: "Lascaine Cúpón",
		orderDiscount: "Ordú Lascaine",
		extraDiscount: "Lascaine Breise",
		finalPrice: "Praghas Deiridh",
		finalTotalPrice: "Praghas Iomlán Deiridh",
		estimatedShipDate: "Dáta Measta na Loinge",
		notes: "Nótaí",
		shipTo: " Seol chuig",
		name: "Ainm",
		check: "Seiceáil",
		APOFPOAddress: "Seoladh APO/FPO",
		Address: "Seoladh",
		GrandTotal: "Iomlán mhór",
		AmountPaid: "Méid a d'íoc",
		BalanceDue: "Iarmhéid Dlite",
		UserLevelDiscount: "Lascaine Leibhéal Úsáideora"
	},
	Quotation: {
		free:"SAOR",
		company:"Cuideachta",
		off:"AS",
		additionalCosts:"Costais Bhreise",
		firstOrderDiscount:"Lascaine Chéad Ordú",
		specialGroup:"Tairiscintí Grúpa Speisialta",
		pointsDeduct:"Asbhaint Pointí",
		giftVouchers:"Dearbháin Bronntanais",
		couponDiscount:"Lascaine Cúpón",
		goldVipMember:"Ball VIP Óir",
		extraDiscount:"Lascaine Breise",
		userLevelDiscount:"Lascaine Leibhéal Úsáideora",
		points:"Pointí",
		title: "RÁITEAS",
		to: "Chun",
		from: "Ó",
		name: "Ainm",
		email: "Ríomhphost",
		telephone: "Teileafón",
		country: "Tír",
		oid: "Aitheantas an Ordaithe",
		date: "Dáta Athfhriotail",
		valid: "Bailí Go dtí",
		contacts: "Teagmhálaithe",
		currency: "Airgeadra",
		no: "Níl.",
		item: "Mír",
		artwork: "Saothar ealaíne",
		description: "Cur síos",
		qty: "Cainníocht",
		unitPrice: "Praghas aonaid",
		moldFee: "Táille Mhúirín",
		extraFee: "Táille Breise",
		apoShippingFee: "Táille Loingseoireachta Apo",
		taxPrice: "Praghas Cánach",
		amount: "Méid",
		remarks: "Nótaí",
		total: "Iomlán",
		titleTerms: "Téarmaí ⁊ Coinníollacha:",
		first: "1. Saothar ealaíne saor in aisce agus Loingseoireacht Saor in Aisce chuig SAM / Ceanada / Eoraip.",
		second: "2. Ní sonrasc atá san fhaisnéis thuas agus ní meastachán é ach ar sheirbhísí/earraí a gcuirtear síos orthu thuas.",
		third: "3.Beidh an táirgeadh tús ar an dáta a fhaigheann muid do cheadúnas cruthúnais agus íocaíocht, le do thoil íoc an nasc seo le haghaidh d'ordú bunaithe ar an cruthúnas ceadaithe. (Nasc Íocaíochta:",
		end: "Go raibh maith agat as do ghnó!",
		tel: "Teil",
		hours: "Uaireanta Oifige",
		chat: "Comhrá Beo",
		address: "Seoladh SAM",
		hoursItem: "Dé Luain - Dé hAoine 8:30 r.n. go 5.30 pm Am an Aigéin Chiúin (PT)",
		chatItem: "24 uair ó Luan go hAoine",
		addressItem: "20829 Gleann Blvd. Gallchnó, CA 91789"
	},
	member:{
		member1:"Ball Airgid",
		member4:"Ball Óir VIP",
		member5:"Ball VIP Platanam",
	},
	...gaLocale
}
