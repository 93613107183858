<template>
<!---->
<!--  <div class="d-flex" style="height: 100%; display: flex;position: relative">-->
    <div class="sideBarWrap">
      <!--      isCollapse折叠状态-->
      <div class="sidebar-logo-container" :class="{isCollapse:isCollapse}">
        <a href="/" class="sidebar-logo-link router-link-active">
<!--          <img src="/img/logo.png"-->
<!--               class="sidebar-logo">-->
          <span class="sidebar-title">{{ $t('systemName') }}</span></a>
      </div>
      <vue-scroll :ops="ops" class="leftScrollBar">
        <el-menu
            :default-active="activeMenu"
            :unique-opened="false"
            class="left-nav"
            background-color="#324157"
            text-color="#fff"
            active-text-color="#ffd04b"
            @select="changeRoute"
            :collapse="isCollapse"
            :collapse-transition="false"
            @open="handleOpen"
        >
          <SidebarItem v-for="route in permission_routes" :key="route.path" :item="route" :basePath="route.path"></SidebarItem>
        </el-menu>
      </vue-scroll>
    </div>
<!--    <div class="fold-icon" @click="changeNav">-->
<!--      <i class="el-icon-arrow-left" :class="{arrow: stopNavHover}"></i>-->
<!--    </div>-->
<!--  </div>-->

</template>

<script>
import SidebarItem from './SidebarItem'
import store from "@/store";

export default {
    name: "Sidebar",
    data() {
        return {
            ops: {
                bar: {
                    background: '#c1c1c1',
                    opacity: 0.7,
                }
            }
        }
    },
    components: {
        SidebarItem
    },
    computed: {
      navBar(){
        return this.$store.state.manage.navBar
      },
      stopNavHover(){
        return this.$store.state.manage.stopBarHover
        // return JSON.parse(localStorage.getItem('stopBarHover'))
      },
      isCollapse(){
        return this.$store.state.manage.navBar
        // return  JSON.parse(localStorage.getItem('lefNavBar'))
      },
      activeMenu() {
            const {meta, path} = this.$route
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },
        permission_routes() {
          console.log(this.$store.getters.permission_routes);
            return this.$store.getters.permission_routes
        }
    },
    methods: {
      async handleOpen(index){
        // 进去订单模块获取未读数据
        // if(index.includes('/InquiryOrderManage') && !store.state.manage.unreadData){
        //   await store.dispatch('manage/updateUnreadData')
        // }
      },
      changeNav(){
        this.$store.commit('manage/changeNavBar', !this.navBar);
        this.$store.commit('manage/stopBarHover', !this.stopNavHover);

        if(localStorage.getItem('lefNavBar') === 'true'){
          localStorage.setItem('lefNavBar','false')
        }
        else {
          localStorage.setItem('lefNavBar','true')
        }

        if(localStorage.getItem('stopBarHover') === 'true'){
          localStorage.setItem('stopBarHover','false')
        }
        else {
          localStorage.setItem('stopBarHover','true')
        }
      },
        changeRoute(index){
           if(index==='/userCenter/memberManage'){
               this.$store.commit('app/set_showUserDetail',false)
           }
        }
    }
};
</script>
<style scoped lang="scss">
.sideBarWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
}


.fold-icon{
  position: absolute;
  right: 0;
  top: 50%;
  height: 50px;
  width: 11px;
  background: #8C95A8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 8px 8px 0;
  i{
    font-size: 10px;

    &.arrow{
      transform: rotate(180deg);
    }
  }
}

.sidebar-logo-container {
    flex-shrink: 0;
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #324157;
    padding: 0 20px;
    overflow: hidden;
    border-bottom: 1px solid #f3f3f3;
  //transition: all 0.3s linear;
    &.isCollapse{
      width: 64px;
    }

    a {
        display: inline-block;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .sidebar-logo {
        max-width: 60px;
        height: 30px;
        vertical-align: middle;
        margin-right: 12px;
    }

    .sidebar-title {
        display: inline-block;
        margin: 0;
        color: #fff;
        line-height: 50px;
        font-size: 17px;
		font-weight: bolder;
        vertical-align: middle;
    }
}

.leftScrollBar {
    overflow: hidden auto;
    flex: 1;
    background-color: rgb(50, 65, 87);
  transition: all 0.3s linear;
  ::v-deep {
    // 展开栏锯齿边去除
    .el-menu{
      border-right:none;
    }
   // 折叠隐藏一级title
   .el-menu--collapse .el-submenu .el-submenu__title .isCollapse{
      width: 0;
      width: 0;
      overflow: hidden;
      visibility: hidden;
      display: inline-block;
    }
    .el-menu--collapse .el-submenu .el-submenu__title .el-icon-arrow-right{
      right: 15px;
    }
    .el-menu--collapse .is-active{
      .el-submenu__title span, .el-icon-arrow-right{
        color: #ffd04b;
      }
    }
  }

    ::v-deep a{
        text-decoration: none;
    }
}
</style>
