import { getUnreadCount, getUnreadQty } from "@/api/o2oApi/dmsManage/order";

const state = {
    userInfo: '',
    proId: 1,
    ptData: JSON.parse(sessionStorage.getItem('ptSelect')) || {},
	ptDataClear:false,
    projectList: [],
    updateUnread:{
        inquiryUnread: 0,
        orderUnread: 0,
        linkOrderUnread: 0
	},
	problemState:'',//内部问题
    currentSite: null,
	orderPaymentType:-1,
    navBar: JSON.parse(localStorage.getItem('lefNavBar')),
    stopBarHover: JSON.parse(localStorage.getItem('stopBarHover')),
    snackbar: {
        snackbar: false,
        snackbarText: '',
        snackbarType: 'error',
    },
    confirmBox: {
        dialog: false,
        title: '',
        content: '',
        cancelText: 'Cancel',
        confirmText: 'Remove',
        confirmFn: '',
        cancelFn: ''
    },
    inquiryStatus: [
        {
            text: 'New Inquiry',
            status: 1
        },
		{
			text: 'Drawing',
			status: 2
		},
        {
            text: 'Quoted',
            status: 3
        },
        {
            text: 'Finish',
            status: 7
        },
        {
            text: 'Ordered',
            status: 8
        },
        {
            text: 'Cancelled',
            status: 9
        },
    ],
    inquirySelectStatus: [
        {
            text: 'New Inquiry',
            status: 1,
            disabled:true
        },
		{
			text: 'Drawing',
			status: 2,
			disabled:true
		},
        {
            text: 'Quoted',
            status: 3,
        },
        {
            text: 'Finish',
            status: 7
        },
        {
            text: 'Ordered',
            status: 8,
            disabled:true
        },
        {
            text: 'Cancelled',
            status: 9,
            disabled:true
        },
    ],
    orderStatus: [
        {
            text: 'New Order',
            status: 1
        },
		{
			text: 'Drawing',
			status: 2
		},
        {
            text: 'Pending Artwork',
            status: 3
        },
        {
            text: 'Modifying',
            status: 4
        },
        {
            text: 'Artwork Confirmed',
            status: 5
        },
        {
            text: 'In Production',
            status: 6
        },
        {
            text: 'Awaiting for Shipping',
            status: 7
        },
        {
            text: 'Shipped',
            status: 8
        },
        {
            text: 'Received',
            status: 9
        },
        {
            text: 'Finished',
            status: 10
        },
        {
            text: 'Cancelled',
            status: 11
        },
    ],
    paymentStatus: [
        {
            text: 'Pay Later',
            status: 0,
			menu: "后支付"
        },
        {
            text: 'Awaiting Payment',
            status: 1,
			menu: "未支付"
        },
        {
            text: 'Received Payment',
            status: 2,
			menu: "已支付"
        },
        {
            text: 'Refunding',
            status: 3,
			menu: "已退款"
        }
    ],
    paymentLaterStatus:[
        {
            text: 'Cash on Delivery',
            status: 1
        },
        {
            text: 'Payment by Instalments',
            status: 2
        },
        {
            text: 'Payment by CC',
            status: 3
        },
        {
            text: 'Net 30 Days',
            status: 4
        } ,
        {
            text: 'Pay by Check',
            status: 5
        }
    ],
    userLevelList: [
        {
            text: 'General',
            status: 1
        },
        {
            text: 'Vip',
            status: 2
        },
    ],
	resetForm: false,
    noStateCountry: [74,81,224,206,89,215]
}

const mutations = {
	selectOrderPayment(state,data){
		state.orderPaymentType = data
	},
	selectLinkPayment(state,data){
		state.linkPaymentType = data
	},
	updatePtSelected(state,data){
        state.ptData = data;
    },
	clearPtSelected(state,data){
		state.ptDataClear = data;
	},
    updateUnread(state,data){
        state.updateUnread = data;
	},
	problemState (state, data) {
		state.problemState = data;
	},
    stopBarHover(state,data){
        state.stopBarHover = data
    },
    changeNavBar(state,data){
        state.navBar = data
    },
    updateSnackbar(state, data) {
        state.snackbar = data
    },
    updateSite(state, data) {
        state.currentSite = data;
        state.proId = data.id;
    },
    updateConfirmBox(state, data) {
        state.confirmBox = data
    },
    updateSiteList(state, data) {
        state.projectList = data;
    },
	updateSalesList(state, data) {
		state.salesList = data;
	},
	changeResetForm(state, data) {
        state.resetForm = data;
    }
}

const actions = {
    updateUnreadData({commit}) {
       return new Promise((resolve,reject) => {
		   getUnreadCount({}).then(res => {
               commit('updateUnread',res.data)
               resolve()
           }).catch(error => {
               console.log(error,666777);
               reject(error)
           })
       })
	},
	getUnreadQtyData ({ commit }) {
		return new Promise((resolve, reject) => {
			getUnreadQty({}).then(res => {
				console.log(res.data, '1213');
				commit('problemState', res.data)
				resolve()
			}).catch(error => {
				reject(error)
				console.log(error,555666);
			})
		})

	}
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
