import layout from "@/views/layout/index.vue";
const labelManage = () => import("@/views/labelManage/labelManage.vue");
const MarketingLabel = () => import("@/views/labelManage/MarketingLabel.vue");
const customerLabelManagement = () => import("@/views/labelManage/customerLabelManagement.vue");
const central = () => import("@/views/centralManage/index.vue");
export default {
	path: "/labelManage",
	name: "labelManage",
	component: layout,
	meta: {
		title: "标签管理",
		icon: "el-icon-user"
	},
	children: [
		/*{
			path: "label",
			name: "label",
			component: labelManage,
			meta: {
				title: "标签管理",
				type: 5
			}
		},
		{
			path: "MarketingLabel",
			name: "MarketingLabel",
			component: MarketingLabel,
			meta: {
				title: "营销中心标签管理",
				type: 5
			}
		},*/
		{
			path: "customerLabelManagement",
			name: "customerLabelManagement",
			component: customerLabelManagement,
			meta: {
				title: "客户标签管理",
				type: 5
			},
			children: []
		}
	]
};
